









































import {
  defineComponent,
  ref,
  computed,
  watch,
  PropType,
} from "@vue/composition-api"

import { formatDateMonth, formatDateShort } from "@/_helper/dateTimeHelper"
import { useI18n } from "@/_i18n"
export default defineComponent({
  name: "DateInput",

  props: {
    label: {
      type: String,
      default: undefined,
    },
    required: Boolean,

    errorMessages: {
      type: Array as PropType<Array<string>>,
      default: undefined,
    },
    disabled: Boolean,
    allowedDates: {
      type: Function as PropType<(date: string) => boolean>,
      default: undefined,
    },
    clearable: Boolean,
    type: {
      type: String,
      default: "date",
    },
    value: {
      type: String,
      default: undefined,
    },
  },

  setup(props, ctx) {
    const { locale } = useI18n()

    const isMenuVisible = ref(false)

    const date = ref<string | null>("")
    const originalDate = ref<string | null>("")

    const format = computed(() => {
      return props.type === "month" ? formatDateMonth : formatDateShort
    })

    const update = () => {
      ctx.emit("input", format.value(date.value))
    }

    const init = () => {
      date.value = props.value
      originalDate.value = props.value
    }

    watch(() => props.value, init)

    const onCancel = () => {
      date.value = originalDate.value
      isMenuVisible.value = false
    }

    const onOk = () => {
      update()
      isMenuVisible.value = false
    }

    const onInputText = (e?: string) => {
      if (e && e !== "") {
        update()
      } else {
        date.value = null
        update()
      }
    }

    return { locale, date, isMenuVisible, onCancel, onOk, format, onInputText }
  },
})
