























import { defineComponent, PropType, computed, h } from "@vue/composition-api"

import { useI18n } from "@/_i18n"
import { AdminConstraint } from "@/_permission"
import { BillingReport } from "@/_service/billing"
import { formatDateShort } from "@/_helper/dateTimeHelper"

import KeyValueBlock from "@/component/KeyValueBlock.vue"
import ClientName from "@/component/ClientName.vue"
import InfoAlert from "@/component/InfoAlert.vue"

import TariffReport from "./TariffReport.vue"

export default defineComponent({
  name: "Report",
  components: { KeyValueBlock, TariffReport, InfoAlert },
  props: {
    report: {
      type: Object as PropType<BillingReport>,
      required: true,
    },
    clientId: {
      type: Number,
      default: undefined,
    },
  },
  setup({ clientId, report }) {
    // translation
    const { t } = useI18n(
      require.context("../", true, /BillingOverview\.[\w-]+\.(json|ya?ml)$/i)
    )

    const isAdmin = AdminConstraint.isSatisfied()

    const basicData = computed(() => [
      ...(isAdmin
        ? [
            {
              key: t("client"),
              value: clientId ? clientId : t("all"),
              slotValue: clientId
                ? h(ClientName, {
                    props: { clientId: clientId },
                  })
                : undefined,
            },
          ]
        : []),
      { key: t("from"), value: formatDateShort(report.startDate) },
      { key: t("to"), value: formatDateShort(report.endDate) },
    ])

    return {
      basicData,
      t,
    }
  },
})
