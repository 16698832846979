
























































import { defineComponent, reactive, computed } from "@vue/composition-api"

import { useI18n } from "@/_i18n"
import { AdminConstraint } from "@/_permission"
import { useApiCall } from "@/_use/apiCall"
import { formatDateMonth } from "@/_helper/dateTimeHelper"
import {
  BillingReport,
  BillingReportQuery,
  getMonthlyReport,
  getReport,
  operations,
} from "@/_service/billing"

import FormCard from "@/component/FormCard.vue"
import DateInput from "@/component/DateInput.vue"
import ClientSelect from "@/component/ClientSelect.vue"
import Loader from "@/component/Loader.vue"

import Report from "./Report.vue"
import { getUser } from "@/_store/user"
import DownloadLink from "@/view/Dashboard/component/DownloadLink.vue"
import { useDownloadLink } from "@/router"

export default defineComponent({
  name: "ReportSubmitCard",

  components: {
    DownloadLink,
    FormCard,
    DateInput,
    ClientSelect,
    Report,
    Loader,
  },

  props: {
    monthly: {
      type: Boolean,
      default: false,
    },
  },

  setup: function ({ monthly }) {
    const { t } = useI18n(
      require.context("../", true, /BillingOverview\.[\w-]+\.(json|ya?ml)$/i)
    )

    const isAdmin = AdminConstraint.isSatisfied()

    const requestMethod = monthly ? getMonthlyReport : getReport

    // form data
    const reportParameter = reactive<BillingReportQuery>({
      month: formatDateMonth(new Date()),
      detailed: false,
      clientId: undefined,
    })

    const link = useDownloadLink(
      monthly
        ? operations.downloadMonthlyBillingReport.url
        : operations.downloadBillingReport.url,
      reportParameter
    )

    if (!isAdmin) {
      reportParameter.clientId = getUser()?.clientId
    }

    // api call
    const {
      isLoading,
      onSubmit,
      result,
      submittedParameter,
      errorMessage,
      hasErrors,
    } = useApiCall<BillingReportQuery, BillingReport>(
      requestMethod,
      t,
      reportParameter
    )

    return {
      t,
      isAdmin,
      reportParameter,
      errorMessage,
      hasErrors,
      isLoading,
      link,
      onSubmit,
      submittedClientId: computed(() => submittedParameter.value?.clientId),
      report: computed(() => result.value.data),
    }
  },
})
