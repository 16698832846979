import {
  BillingReportDTO,
  ErrorResponseDTO,
  GetBillingMonthlyReportQuery,
  GetBillingReportQuery,
  TariffReportDTO,
} from "@/_service/api-docs"
import { fetchData, FetchDataResult, FetchOperation } from "@/_service/http"

export type BillingReport = BillingReportDTO
export type BillingReportQuery =
  | GetBillingReportQuery
  | GetBillingMonthlyReportQuery
export type TariffReport = TariffReportDTO

/**
 * HTTP API operations (as URL path suffixes), which can be performed by the
 * service
 */
export const operations: Record<string, FetchOperation> = {
  getBillingReport: { method: "get", url: "billing/read" },
  downloadBillingReport: { method: "get", url: "billing/download" },
  downloadMonthlyBillingReport: {
    method: "get",
    url: "billing/monthly/download",
  },
  getMonthlyBillingReport: { method: "get", url: "billing/monthly/read" },
}

/**
 * request a report with the given parameter
 *
 * @param parameter the parameter to submit with the request
 */
export const getReport = async (
  parameter: GetBillingReportQuery
): Promise<FetchDataResult<BillingReportDTO>> => {
  return await fetchData<
    GetBillingReportQuery,
    BillingReportDTO,
    ErrorResponseDTO
  >(operations.getBillingReport, parameter)
}

/**
 * request a monthly report with the given parameter
 *
 * @param parameter the parameter to submit with the request
 */
export const getMonthlyReport = async (
  parameter: GetBillingMonthlyReportQuery
): Promise<FetchDataResult<BillingReportDTO>> => {
  return await fetchData<
    GetBillingReportQuery,
    BillingReportDTO,
    ErrorResponseDTO
  >(operations.getMonthlyBillingReport, parameter)
}
