































import { defineComponent, PropType, ref } from "@vue/composition-api"

import BaseDialog from "@/component/BaseDialog.vue"
import { useI18n } from "@/_i18n"

export default defineComponent({
  name: "DetailsDialog",

  components: { BaseDialog },

  props: {
    details: {
      type: Array as PropType<string[]>,
      default: [],
    },
    count: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      required: false,
    },
  },

  setup() {
    const { t } = useI18n(
      require.context("../", true, /BillingOverview\.[\w-]+\.(json|ya?ml)$/i)
    )

    const dialog = ref(false)

    return { t, dialog }
  },
})
