/**
 * Formats date to iso format 'YYYY-MM-DD HH:mm'
 *
 * @param {Date|Number|String} input      date value to be formatted
 */
export const formatDate = (
  input: Date | number | string | null | undefined
): string => {
  let formatted = ""

  if (input) {
    const date = new Date(input)
    const dateStr = date.toISOString()
    formatted = `${dateStr.substr(0, 10)} ${dateStr.substr(11, 5)}`
  }

  return formatted
}

/**
 * Formats time to iso format 'HH:mm'
 *
 * @param {Date|Number|String} input      date value to be formatted
 */
export const formatTime = (
  input: Date | number | string | null | undefined
): string => {
  let formatted = ""

  if (input) {
    const date = new Date(input)
    const dateStr = date.toISOString()
    formatted = `${dateStr.substr(11, 5)}`
  }

  return formatted
}

/**
 * Formats date to iso format 'YYYY-MM-DD'
 *
 * @param {Date|Number|String} input      date value to be formatted
 */
export const formatDateShort = (
  input: Date | number | string | null | undefined
): string => {
  let formatted = ""

  if (input) {
    const date = new Date(input)
    formatted = date.toISOString().substr(0, 10)
  }

  return formatted
}

/**
 * Formats date to format 'YYYY-MM'
 *
 * @param {Date|Number|String} input      date value to be formatted
 */
export const formatDateMonth = (
  input: Date | number | string | null | undefined
): string => {
  let formatted = ""

  if (input) {
    const date = new Date(input)
    formatted = date.toISOString().substr(0, 7)
  }

  return formatted
}

export default {
  formatDate,
  formatDateShort,
  formatTime,
  formatDateMonth,
}
