



















import { defineComponent, ref } from "@vue/composition-api"

import { useI18n } from "@/_i18n"
import { AdminAccountingConstraint } from "@/_permission"

import ReportSubmitCard from "./component/ReportSubmitCard.vue"

export default defineComponent({
  name: "BillingOverview",

  components: { ReportSubmitCard },

  setup() {
    // translation
    const { t } = useI18n(
      require.context("../", true, /BillingOverview\.[\w-]+\.(json|ya?ml)$/i)
    )

    const tab = ref(0)

    const isAdmin = AdminAccountingConstraint.isSatisfied()

    return { t, tab, isAdmin }
  },
})
