











import { defineComponent, PropType, computed, h } from "@vue/composition-api"

import { useI18n } from "@/_i18n"
import { TariffReport } from "@/_service/billing"

import KeyValueBlock from "@/component/KeyValueBlock.vue"

import DetailsDialog from "./DetailsDialog.vue"

export default defineComponent({
  name: "TariffReport",
  components: { KeyValueBlock },
  props: {
    report: {
      type: Object as PropType<TariffReport>,
      required: true,
    },
  },
  setup({ report }) {
    // translation
    const { t } = useI18n(
      require.context("../", true, /BillingOverview\.[\w-]+\.(json|ya?ml)$/i)
    )

    const detailsAvailable = computed(
      () =>
        report.createDetails?.length ||
        report.renewDetails?.length ||
        report.deletedAGPDetails?.length ||
        report.deletedARGPDetails?.length
    )

    const basicData = computed(() => [
      {
        key: t("createdMailboxes"),
        slotValue: h(DetailsDialog, {
          props: {
            count: report.createdMailboxes,
            details: report.createDetails,
            title: t("createdMailboxes"),
          },
        }),
      },
      {
        key: t("renewedMailboxes"),
        value: report.renewedMailboxes,
        slotValue: h(DetailsDialog, {
          props: {
            count: report.renewedMailboxes,
            details: report.renewDetails,
            title: t("renewedMailboxes"),
          },
        }),
      },
      {
        key: t("deletedMailboxesAGP"),
        value: report.deletedMailboxesAGP,
        slotValue: h(DetailsDialog, {
          props: {
            count: report.deletedMailboxesAGP,
            details: report.deletedAGPDetails,
            title: t("deletedMailboxesAGP"),
          },
        }),
      },
      {
        key: t("deletedMailboxesARGP"),
        value: report.deletedMailboxesARGP,
        slotValue: h(DetailsDialog, {
          props: {
            count: report.deletedMailboxesARGP,
            details: report.deletedARGPDetails,
            title: t("deletedMailboxesARGP"),
          },
        }),
      },
    ])

    return {
      t,
      basicData,
      detailsAvailable,
    }
  },
})
